import { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useInView } from "react-cool-inview";
import { Menu } from "@headlessui/react";
import {
  UilAngleDown as ArrowDownIcon,
  UilSignOutAlt as LogoutIcon,
  UilCommentDots as MessageIcon,
} from "@iconscout/react-unicons";
import { UisUsersAlt as ImpersonateUserIcon } from "@iconscout/react-unicons-solid";

import useUser from "hooks/useUser";
import { useAuthState } from "context/auth-context";
import SocialIcons from "components/social-icons";
import Logo from "components/logo";
import colors from "utils/colors";
import useGeolocationInfo from "hooks/useGeolocationInfo";

const PhoneIcon = ({ hasMobileBg, hasDesktopBg }) => (
  <svg
    className={cx(
      hasMobileBg ? "text-accent-700" : "text-white",
      hasDesktopBg ? "md:text-accent-700" : "md:text-white"
    )}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.025 10.7167L11.9084 10.475C11.4 10.4167 10.9 10.5917 10.5417 10.95L9.00837 12.4834C6.65003 11.2834 4.7167 9.35836 3.5167 6.9917L5.05837 5.45003C5.4167 5.09169 5.5917 4.5917 5.53337 4.08336L5.2917 1.98336C5.1917 1.1417 4.48337 0.508362 3.63337 0.508362H2.1917C1.25003 0.508362 0.466701 1.2917 0.525034 2.23336C0.966701 9.35003 6.65837 15.0334 13.7667 15.475C14.7084 15.5334 15.4917 14.75 15.4917 13.8084V12.3667C15.5 11.525 14.8667 10.8167 14.025 10.7167Z"
      fill="currentColor"
    />
  </svg>
);

const PhoneBtn = ({ hasMobileBg = false, hasDesktopBg = false }) => (
  <Menu as="div" className="lg:relative">
    {({ open }) => (
      <>
        <div>
          <Menu.Button as={Fragment}>
            {open ? (
              <CloseBtn />
            ) : (
              <button
                className={cx(
                  "flex h-10 w-10 items-center justify-center rounded-full",
                  hasMobileBg ? "border border-accent-200 bg-white" : "border border-white/20 bg-black/10",
                  hasDesktopBg ? "md:border-accent-200 md:bg-white" : "md:border-white/20 md:bg-black/10"
                )}
              >
                <PhoneIcon hasMobileBg={hasMobileBg} hasDesktopBg={hasDesktopBg} />
              </button>
            )}
          </Menu.Button>
        </div>
        <Menu.Items className="absolute right-0 left-0 mt-5 w-full origin-top-right divide-y border border-neutral-200 bg-white text-accent-800 shadow-xl focus:outline-none lg:-right-20 lg:left-auto lg:mt-2 lg:w-[25rem]">
          <div className="px-8">
            <div className="border-b border-neutral-200 py-9">
              <span className="text-2xl font-medium">Text Us</span>
              <p className="mt-2 text-sm font-light">Get your questions answered fast by text.</p>
              <div className="mt-5 flex items-center gap-x-2 text-sm">
                <MessageIcon className="h-5 w-5" />{" "}
                <a href="sms:+13235535563" className="text-accent-700 underline">
                  (323) 553-5563
                </a>
              </div>
            </div>
            <div className="py-9">
              <span className="text-2xl font-medium">Call Us</span>
              <p className="mt-2 text-sm font-light">
                Jump on the phone with us between 8:00am and 8:00pm PST any day of the week.
              </p>
              <div className="mt-5 flex items-center gap-x-2 text-sm">
                <PhoneIcon hasMobileBg hasDesktopBg />{" "}
                <a href="tel:+13235535563" className="text-accent-700 underline">
                  (323) 553-5563
                </a>
              </div>
            </div>
          </div>
        </Menu.Items>
      </>
    )}
  </Menu>
);

const SignInBtn = () => (
  <Link href="https://client.buildappeal.com/login" passHref>
    <a className="btn secondary  text-neutral-300">Sign in</a>
  </Link>
);

const SignOutBtn = ({ onClick }) => (
  <a className="btn secondary  text-neutral-300" onClick={onClick}>
    Log out
  </a>
);

const UserBtn = ({ user, onLogout, isImpersonateMode }) => (
  <Menu as="div" className="relative">
    {({ open }) => (
      <>
        <div>
          <Menu.Button className="btn secondary text-neutral-300">
            {isImpersonateMode ? <ImpersonateUserIcon className="mr-2 h-4 w-4 text-red-700" /> : null} Hi,{" "}
            {user?.firstName} <ArrowDownIcon className={cx("ml-2 h-4 w-4", open && "rotate-180")} />
          </Menu.Button>
        </div>
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y rounded-md border border-neutral-200 bg-white shadow-lg focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              <button className="flex w-full items-center justify-between py-3 px-5" onClick={onLogout}>
                <span className="text-md">Logout</span>
                <LogoutIcon className="text-accent-300" />
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </>
    )}
  </Menu>
);

const GetStartedBtn = () => (
  <Link href="/onboarding/1" passHref>
    <a className="btn primary text-sm font-medium text-neutral-300">
      <span className="hidden lg:inline">Get a Free Estimate</span>
      <span className="inline lg:hidden">Free Estimate</span>
    </a>
  </Link>
);

const MyProjectBtn = () => (
  <Link href="/dashboard" passHref>
    <a className="btn primary text-sm font-medium text-neutral-300">My Project</a>
  </Link>
);

const MenuBtn = (props) => (
  <button className="flex h-10 w-10 items-center justify-center rounded-full bg-white" {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="5" width="20" height="2" rx="1" fill={colors.green} />
      <rect y="13" width="20" height="2" rx="1" fill={colors.green} />
    </svg>
  </button>
);

const CloseBtn = (props) => (
  <button className="flex h-10 w-10 items-center justify-center rounded-full bg-neutral-200" {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.18384"
        y="3.11902"
        width="16.7432"
        height="1.67432"
        rx="0.83716"
        transform="rotate(45 4.18384 3.11902)"
        fill={colors.green}
      />
      <rect
        x="3"
        y="14.8392"
        width="16.7432"
        height="1.67432"
        rx="0.83716"
        transform="rotate(-45 3 14.8392)"
        fill={colors.green}
      />
    </svg>
  </button>
);

const Header = ({ data, isTransparent, social, mobileOverlay = "", desktopOverlay = "" }) => {
  const { authState } = useAuthState();
  const { user, logout } = useUser();
  const router = useRouter();
  const { observe, inView: observerIsVisible, entry } = useInView();
  const identifyUserRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const { geoLocationInfo, isLoading: geolocationInfoLoading } = useGeolocationInfo();

  const hasMobileBg = isTransparent && mobileOverlay !== "dark" ? (!!entry && !observerIsVisible) || isOpen : true;
  const hasDesktopBg = isTransparent && desktopOverlay !== "dark" ? (!!entry && !observerIsVisible) || isOpen : true;

  const isInDashboard = router.pathname.includes("/dashboard");

  useEffect(() => {
    if (
      !user?.email ||
      (!!identifyUserRef?.current?.email && user.email == identifyUserRef.current.email) ||
      geolocationInfoLoading
    ) {
      return;
    }
    window.analytics?.identify?.(
      user?.id,
      {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      },
      {
        context: {
          location: geoLocationInfo?.location,
          ip: geoLocationInfo?.ip,
        },
      }
    );
    window.Kustomer?.describeCustomer?.({
      attributes: {
        emails: [user.email],
      },
    });
    identifyUserRef.current = user;
  }, [user, geolocationInfoLoading, geoLocationInfo]);

  const links = data?.map(({ target, label }) => {
    let to = (target?.isInternal ? target?.page?.slug : target?.url) || "/";
    to = to.startsWith("/") || to.startsWith("http") ? to : `/${to}`;
    return {
      label,
      to,
      target,
    };
  });

  return (
    <>
      <div ref={observe} className="h-[.1px]"></div>
      <nav
        className={cx(
          "sticky top-0 z-20 flex h-20 w-full transition-colors duration-300",
          hasMobileBg ? "bg-[#FBFBF6]" : "bg-transparent",
          hasDesktopBg ? "md:bg-[#FBFBF6]" : "md:bg-transparent"
        )}
      >
        <div className="m-auto flex w-full items-center  justify-between px-2  align-middle sm:px-8 md:max-w-[1280px] lg:max-w-[1680px] lg:px-6">
          <div className="flex items-center align-middle md:mr-10">
            <Link href="/" passHref>
              <a className="px-3 py-1.5">
                <Logo
                  color="currentColor"
                  className={cx(
                    hasMobileBg ? "text-[#000000e0]" : "text-white",
                    hasDesktopBg ? "md:text-[#000000e0]" : "md:text-white"
                  )}
                />
              </a>
            </Link>
          </div>
          <div className="justify-left hidden w-full items-center gap-x-3 lg:flex">
            {links?.map(({ target, label, to }) => (
              <Link key={label} href={to} as={to} passHref>
                <a
                  title={target?.title || label}
                  className={cx(
                    "rounded-full px-3 py-2 text-sm",
                    hasMobileBg ? "hover:bg-[#f5f1e8] active:bg-accent-100" : "text-white",
                    hasDesktopBg
                      ? "md:text-[#000000e0] md:hover:bg-[#f5f1e8] md:active:bg-accent-100"
                      : "md:text-white md:hover:bg-transparent md:active:bg-transparent"
                  )}
                >
                  {label}
                </a>
              </Link>
            ))}
          </div>
          <div className="flex items-center gap-x-2">
            <PhoneBtn hasMobileBg={hasMobileBg} hasDesktopBg={hasDesktopBg} />
            {!authState?.isLoggedIn && <GetStartedBtn />}
            {authState?.isLoggedIn && !isInDashboard && <MyProjectBtn />}
            {!authState?.isLoggedIn && (
              <div className="hidden lg:block">
                <SignInBtn />
              </div>
            )}
            {authState?.isLoggedIn && isInDashboard && (
              <div className="hidden lg:block">
                <UserBtn user={user} onLogout={logout} isImpersonateMode={authState.isImpersonateMode} />
              </div>
            )}
            <div className="lg:hidden">
              {isOpen ? <CloseBtn onClick={() => setIsOpen(false)} /> : <MenuBtn onClick={() => setIsOpen(true)} />}
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="absolute top-full left-0 right-0 z-10 h-[calc(100vh-80px)] overflow-y-auto border-t border-neutral-200 bg-neutral-100 lg:hidden">
            <div className="grid min-h-[calc(100vh-81px)] w-full grid-rows-[auto_1fr]">
              <div className="mble min-h-full pt-10 pb-5">
                <div className="flex flex-col text-center">
                  {links?.map(({ target, label, to }) => (
                    <Link key={label} href={to} as={to} passHref>
                      <a
                        title={target?.title || label}
                        className={cx(
                          "rounded p-4 text-2xl font-medium",
                          hasMobileBg ? "hover:bg-accent-50 active:bg-accent-100" : ""
                        )}
                        onClick={() => setIsOpen(false)}
                      >
                        {label}
                      </a>
                    </Link>
                  ))}
                </div>
                <div className="mx-4 mt-10 flex items-center justify-between border-t border-neutral-200 py-6">
                  <span>{authState?.isLoggedIn ? `Hi, ${user?.firstName}` : "Already a client with us?"}</span>
                  {authState?.isLoggedIn ? <SignOutBtn onClick={logout} /> : <SignInBtn />}
                </div>
                <div className="mx-4 flex items-center justify-center gap-x-6 border-t border-neutral-200 py-6">
                  {social?.map((item) => (
                    <a
                      key={item.icon}
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      className=" hover:text-gray-500"
                    >
                      <span className="sr-only">{item.icon}</span>
                      <SocialIcons icon={item.icon} className="h-5 w-5" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
              {!authState?.isLoggedIn && (
                <div className="h-full bg-accent-700 p-6">
                  <div className="flex flex-col">
                    <span className="text-center text-md font-medium text-white">Not sure where to start?</span>
                    <button className="mt-3 w-full rounded-full bg-white py-3">Give us a call</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

Header.propTypes = {
  isTransparent: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
};

export default Header;
