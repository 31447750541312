import React from "react";
import PropTypes from "prop-types";

const Logo = ({ className, color = "current" }) => {
  return (
    <div className={className}>
      <svg width="125" height="30" viewBox="0 0 113 20" fill="#fff" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.860107 16H6.96011C10.3401 16 12.2401 14.36 12.2401 12.06C12.2401 10.22 10.9401 8.69996 8.24011 8.51996C10.6201 8.31996 11.7801 6.85996 11.7801 5.31996C11.7801 3.39996 10.1001 1.99996 7.00011 1.99996H0.860107C2.00011 3.17996 2.00011 3.17996 2.00011 3.65996V14.34C2.00011 14.82 2.00011 14.82 0.860107 16ZM4.92011 15.26V8.91996H6.46011C8.12011 8.91996 9.24011 10.1 9.24011 12.1C9.24011 14.08 8.12011 15.26 6.46011 15.26H4.92011ZM4.92011 8.21996V2.73996H6.28011C7.96011 2.73996 8.72011 3.79996 8.72011 5.43996C8.72011 7.13996 7.96011 8.21996 6.28011 8.21996H4.92011Z"
          fill={color}
        />
        <path
          d="M20.7446 8.55996V13.9C20.4046 14.34 19.7646 14.62 19.1046 14.62C17.9446 14.62 17.1246 13.94 17.1246 12.48V5.83996L13.1446 7.11996C14.3446 7.83996 14.3446 7.83996 14.3446 8.55996V12.84C14.3446 14.82 15.4246 16.3 17.4846 16.3C18.8446 16.3 20.1046 15.38 20.7446 14.26V16.3L24.7446 15.38C23.5446 14.54 23.5446 14.54 23.5446 13.96V5.83996L19.5646 7.11996C20.7446 7.83996 20.7446 7.83996 20.7446 8.55996Z"
          fill={color}
        />
        <path
          d="M25.2735 16H30.3135C29.1935 14.98 29.1935 14.98 29.1935 14.36V5.83996L25.2135 7.11996C26.3935 7.83996 26.3935 7.83996 26.3935 8.55996V14.36C26.3935 14.98 26.3935 14.98 25.2735 16ZM26.1135 3.15996C26.1135 4.11996 26.8335 4.79996 27.7935 4.79996C28.7735 4.79996 29.4735 4.11996 29.4735 3.15996C29.4735 2.23996 28.7735 1.51996 27.7935 1.51996C26.8335 1.51996 26.1135 2.23996 26.1135 3.15996Z"
          fill={color}
        />
        <path
          d="M30.7171 16H35.7771C34.6371 14.98 34.6371 14.98 34.6371 14.36V0.959961L30.6571 2.25996C31.8571 2.95996 31.8571 2.95996 31.8571 3.67996V14.36C31.8571 14.98 31.8571 14.98 30.7171 16Z"
          fill={color}
        />
        <path
          d="M44.1612 3.67996V6.21996C43.5412 5.97996 42.8412 5.83996 42.0612 5.83996C38.7012 5.83996 36.6212 8.27996 36.6212 11.38C36.6212 14.38 38.4012 16.3 40.8812 16.3C42.2812 16.3 43.4412 15.6 44.1612 14.48V16.3L48.1612 15.38C46.9612 14.54 46.9612 14.54 46.9612 13.96V0.959961L42.7612 2.25996C44.1612 2.95996 44.1612 2.95996 44.1612 3.67996ZM39.5412 10.54C39.5412 7.91996 40.5812 6.57996 41.8412 6.57996C43.0412 6.57996 43.8012 7.35996 44.1612 8.45996V14.1C43.7812 14.46 43.2012 14.66 42.4812 14.66C40.6012 14.66 39.5412 13.04 39.5412 10.54Z"
          fill={color}
        />
        <path
          d="M46.2 16H49.8C48.1 14.82 48.1 14.82 48.44 14.02L49.44 11.7H54.8L55.76 13.96C56.08 14.76 56.08 14.76 54.36 16H60.18C59.46 14.94 59.46 14.94 58.94 13.78L53.68 1.89996H52.76L47.52 13.7C46.98 14.84 46.98 14.84 46.2 16ZM49.78 10.9L52.14 5.41996L54.46 10.9H49.78Z"
          fill={color}
        />
        <path
          d="M61.5559 8.55996V17.96C61.5559 18.58 61.5559 18.58 60.4359 19.6H65.8959C64.3559 18.58 64.3559 18.58 64.3559 17.96V15.9C64.9759 16.16 65.6759 16.3 66.4559 16.3C69.8159 16.3 71.8959 13.86 71.8959 10.76C71.8959 7.75996 70.1159 5.83996 67.6559 5.83996C66.2359 5.83996 65.0759 6.51996 64.3559 7.63996V5.83996L60.3759 7.11996C61.5559 7.83996 61.5559 7.83996 61.5559 8.55996ZM64.3559 13.68V8.03996C64.7359 7.67996 65.3159 7.45996 66.0359 7.45996C67.9159 7.45996 68.9759 9.07996 68.9759 11.6C68.9759 14.22 67.9359 15.54 66.6959 15.54C65.4759 15.54 64.7159 14.78 64.3559 13.68Z"
          fill={color}
        />
        <path
          d="M73.9145 8.55996V17.96C73.9145 18.58 73.9145 18.58 72.7945 19.6H78.2545C76.7145 18.58 76.7145 18.58 76.7145 17.96V15.9C77.3345 16.16 78.0345 16.3 78.8145 16.3C82.1745 16.3 84.2545 13.86 84.2545 10.76C84.2545 7.75996 82.4745 5.83996 80.0145 5.83996C78.5945 5.83996 77.4345 6.51996 76.7145 7.63996V5.83996L72.7345 7.11996C73.9145 7.83996 73.9145 7.83996 73.9145 8.55996ZM76.7145 13.68V8.03996C77.0945 7.67996 77.6745 7.45996 78.3945 7.45996C80.2745 7.45996 81.3345 9.07996 81.3345 11.6C81.3345 14.22 80.2945 15.54 79.0545 15.54C77.8345 15.54 77.0745 14.78 76.7145 13.68Z"
          fill={color}
        />
        <path
          d="M90.9531 16.3C93.7731 16.3 95.0331 14.76 95.3131 13.26C94.6731 13.98 93.5931 14.32 92.3931 14.32C89.8931 14.32 88.3331 12.54 88.3331 10.16V10.08H95.4931C95.4931 7.85996 93.7331 5.83996 90.6331 5.83996C87.4931 5.83996 85.6531 8.33996 85.6531 11.14C85.6531 14.3 87.8531 16.3 90.9531 16.3ZM88.3331 9.49996C88.3931 7.57996 89.2131 6.43996 90.5731 6.43996C91.9731 6.43996 92.7131 7.67996 92.7531 9.49996H88.3331Z"
          fill={color}
        />
        <path
          d="M104.663 16.3C105.923 16.3 106.843 15.44 107.123 14.32C106.863 14.56 106.583 14.64 106.283 14.64C105.683 14.64 105.343 14.34 105.343 13.62V9.85996C105.343 7.29996 103.883 5.83996 101.323 5.83996C99.0026 5.83996 97.3626 6.91996 96.9226 8.83996C97.6626 7.91996 98.5426 7.53996 99.7226 7.53996C101.443 7.53996 102.543 8.45996 102.543 10.06V10.62L100.483 10.98C97.9626 11.42 96.6426 12.4 96.6426 13.88C96.6426 15.34 97.9226 16.3 99.5426 16.3C100.843 16.3 102.023 15.44 102.563 14.48C102.663 15.66 103.463 16.3 104.663 16.3ZM99.4426 13.14C99.4426 12.26 100.083 11.52 101.363 11.34L102.543 11.16V14.14C102.203 14.48 101.763 14.7 101.143 14.7C100.183 14.7 99.4426 14.2 99.4426 13.14Z"
          fill={color}
        />
        <path
          d="M107.235 16H112.295C111.155 14.98 111.155 14.98 111.155 14.36V0.959961L107.175 2.25996C108.375 2.95996 108.375 2.95996 108.375 3.67996V14.36C108.375 14.98 108.375 14.98 107.235 16Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

Logo.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Logo;
