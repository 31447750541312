const colors = {
  transparent: "transparent",
  current: "currentColor",
  white: "#ffffff",
  black: "#000000",
  brown: "#0E272F",
  green: "#183837",
  blacktext: "#292a2d",
  boh: "#944d38",
  apl: "#000000e0",
  aman: "#f3eee7",
  neutral: {
    100: "#fbfbf6",
    200: "#EEECE3",
    300: "#f3eee7",
  },
};

module.exports = colors;
